import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import logo from '../images/logo/Horizontal_Transparent.png'
import '../styles/header.css';

const Header = (props) => {
  const { links, path } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className='header full-width-background'>
      <Navbar dark expand="md">
        <NavbarBrand href='/'>
          <img
            src={logo}
            width="198px"
            height="40px"
            className="d-inline-block align-top"
            alt="PriorityQ's Logo"
            style={
              { margin: 0 }
            }
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar></Nav>
          <Nav navbar className='navBarPadding'>
            {Header.renderLinks(links, path)}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  logoPath: PropTypes.string,
  links: PropTypes.array
}

Header.defaultProps = {
  siteTitle: `Priority Q`,
}

Header.renderLinks = (links, path) => {
  console.log(path, links.map(link => link.path))
  const renderedNavItems = (
    links.map((link) => {
      const cssClass = path === link.path ? 'navItem activeLink' : 'navItem'
      return (
        <NavItem id={link.path}>
          <Link to={link.path}>
            <NavLink
              className={cssClass}>
              <span>
                {link.name}
              </span>
            </NavLink>
          </Link>
        </NavItem >
      )
    })
  );

  return (renderedNavItems)
}

export default Header
