import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { navigate } from 'gatsby'

import PqButton from './pqButton'
import "./footer.css"
import logo from '../images/logo-transparent.png'

const Layout = () => {

  return (
    <footer className='full-width-background'>
      <div className='footer-main'>
        <div className='footer-left'>
          <img src={logo} alt="PrioirtyQ's Logo" />
          <p id='marketing-excerpt'>Your priority is our priority. We strive to provide robust solutions for your technical problems.</p>
          <PqButton onClick={() => navigate('/contacts')}>Contact Us</PqButton>
        </div>
        <div className='footer-right'>
          <div className='footer-right-section'>
            <Link to="/"><span>Home</span></Link>
            <Link to="/about"><span>About Us</span></Link>
            <Link to="/services"><span>Services</span></Link>
            <Link to="/contacts"><span>Contact Us</span></Link>
          </div>
          <div className='footer-right-section'>
            <a href="/services/?type=webMobile"><span>Web / Mobile</span></a>
            <a href="/services/?type=geospatial"><span>Geospatial</span></a>
            <a href="/services/?type=cloud"><span>Cloud</span></a>
            <a href="/services/?type=businessIntelligence"><span>Business Intelligence</span></a>
          </div>
        </div>
      </div>
      <div className='footer-sub'>
        <div className='footer-sub-left'>{new Date().getFullYear()} All Rights Reserved</div>
        <div className='footer-sub-right'>
          <a href='https://www.linkedin.com/company/priorityq/'>
            <FontAwesomeIcon icon={faLinkedin} color="#006192" size="lg" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Layout
