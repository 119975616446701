/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ path, children }) => {
  const pages = [
    {
      name: 'Home',
      path: '/'
    }, {
      name: 'About Us',
      path: '/about/'
    },
    {
      name: 'Services',
      path: '/services/'
    },
    {
      name: 'Contact Us',
      path: '/contacts/'
    }
  ]
  return (
    <div id="pq-main-content" style={{}}>
      <Header
        siteTitle="Priority Q"
        links={pages}
        path={path}
      />
      <div id='pq-content-body'>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
