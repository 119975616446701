import React from 'react'
import { Button } from 'reactstrap';
import './pqButton.css'

const PqButton = ({ children, customClassName, onClick, style, type }) => {
  return (
    <Button
      style={style}
      className={`pq-button ${customClassName || ''}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  )
}

export default PqButton;